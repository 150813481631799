
import { defineComponent, ref, reactive } from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import {
    listPagination, ListHeaderItem, RequestData, getSearch,
    ListActionItem
} from '@/components/common/list';
import router from '@/router';
import { propertyBaseRouter, propertySubRouter } from '@/router/data';
import { payProcessing, cancel } from '@/components/view/common/payment';
import noticeBase from '@/util/notice.base';

const breadCrumb = [{ label: WordList.NavInHtmlMenuOrder }];
const headers: Array<ListHeaderItem> = [{
    name: 'OrderNumber',
    label: WordList.OrderOrderNumber
}, {
    name: 'Type',
    label: WordList.TabelKeyInHtmlType
}, {
    name: 'AptNumber',
    label: WordList.ProperAllTextCounts
}, {
    name: 'TotalPrice',
    label: WordList.OrderTotalPrice
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];
const typeOptions = [{
    value: 'all',
    label: WordList.SelectAll
}, {
    value: '1',
    label: WordList.ProperAllTextActivate
}, {
    value: '2',
    label: WordList.ProperAllTextRenew
}, {
    value: '3',
    label: WordList.AdditionalApp
}, {
    value: '9',
    label: WordList.AutoRenew
}
// 2021.12.06 v6.2 modify by lxf,隐藏Feature Plan付款入口和订单记录
// , {
//     value: '5',
//     label: WordList.ProperAllTextFeature
// }
];
const statusOptions = [{
    value: 'all',
    label: WordList.SelectAll
}, {
    value: '0',
    label: WordList.ProperAllTextProcessing
}, {
    value: '1',
    label: WordList.PropertyAllTextCompleted
}, {
    value: '2',
    label: WordList.PropertyAllTextFailed
}, {
    value: '3',
    label: WordList.ProperAllTextTimeOut
}, {
    value: '5',
    label: WordList.TabelFootCancel
}, {
    value: '6',
    label: WordList.ProperAllTextSystemProcessing
}];

export default defineComponent({
    components: {
        listPagination,
        propertyBreadCrumb,
        payProcessing
    },
    setup() {
        const requestData: RequestData = reactive({
            url: 'v3/web/community/order/getListForPm',
            param: getSearch({
                Type: 'all',
                Status: 'all',
                Key: ''
            })
        });
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const searchList = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };

        const goInfo = (value: { ID: string }) => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.paymentInfo}?id=${value.ID}`);
        };

        const isShowPayprocessing = ref(false);
        const payJumpUrl = ref('');
        const action: ListActionItem[] = [{
            type: 'pay',
            event(val: { bmurl: string }) {
                isShowPayprocessing.value = true;
                payJumpUrl.value = val.bmurl;
                window.open(val.bmurl);
            },
            class: 'el-icon-my-pay modify-icon',
            showCondition(val: { StatusEnum: string }) {
                return val.StatusEnum === '0';
            },
            title: WordList.OrderPay
        },
        {
            type: 'cancelpay',
            event(val: {OrderNumber: string; BmOrderNumber: string; PayCode: string}) {
                noticeBase.messageBox('confirm', WordList.TabelPaymentCancel, WordList.TabelDeleteTitle, 'warning')(() => {
                    cancel.cancelOrder({ OrderNumber: val.OrderNumber }, () => {
                        updateToList.value += 1;
                    });
                });
            },
            class: 'el-icon-my-cancel-payment cancelpay-icon',
            showCondition(val: { StatusEnum: string }) {
                return val.StatusEnum === '0';
            },
            title: WordList.TabelFootCancel
        }];

        const successPay = () => {
            isShowPayprocessing.value = false;
            updateToList.value += 1;
        };

        return {
            headers,
            requestData,
            breadCrumb,
            typeOptions,
            statusOptions,
            updateToList,
            goInfo,
            searchList,
            saveParamInPath,
            action,
            isShowPayprocessing,
            successPay,
            payJumpUrl
        };
    }
});
