
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { defineComponent, PropType, ref } from 'vue';
import { subscriptionApi } from '@/api';

export default defineComponent({
    emits: ['isShowPayNow', 'close'],
    components: {
        dialogShell
    },
    props: {
        mainId: {
            type: String,
            required: true
        },
        // 主账号填手机邮箱或从账号填手机邮箱
        noticeType: {
            type: String as PropType<'main' | 'sub'>,
            required: true
        },
        // 从账号未填写手机邮箱，主账号的提示语code
        noticeMainCode: {
            type: Number as PropType<0 | 1 | 2>,
            required: true
        },
        projectType: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const PayNow = () => {
            emit('isShowPayNow');
        };
        const addAppFee = ref(0);

        const showText = ref('');
        const tips = [
            WordList.InstallerPmPayByMeMainEmail,
            WordList.InstallerPmPayByMeMainPhone,
            WordList.InstallerPmPayByMeMainNull
        ];
        if (props.projectType === 'community') {
            subscriptionApi.getCommunitySubUserPay({ ID: props.mainId }, (data: {
                addAppFee: number;
            }) => {
                addAppFee.value = Number(data.addAppFee);
                if (props.noticeType === 'main') {
                    showText.value = tips[props.noticeMainCode].format(addAppFee.value.toString());
                } else {
                    showText.value = WordList.InstallerPmPayByMeSubEmailOrPhone.format(addAppFee.value.toString());
                }
            });
        } else if (props.projectType === 'single') {
            subscriptionApi.getSingleSubUserPay({ ID: props.mainId }, (data: {
                addAppFee: number;
            }) => {
                addAppFee.value = Number(data.addAppFee);
                if (props.noticeType === 'main') {
                    showText.value = tips[props.noticeMainCode].format(addAppFee.value.toString());
                } else {
                    showText.value = WordList.InstallerPmPayByMeSubEmailOrPhone.format(addAppFee.value.toString());
                }
            });
        }

        return {
            PayNow,
            addAppFee,
            showText
        };
    }
});
