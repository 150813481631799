
import {
    defineComponent, reactive, ref, computed,
    PropType, watch
} from 'vue';
import { orderType } from '@/data';
import { UserType } from '@/components/view/common/subscription/all.type';
import { subscriptionApi } from '@/api';

interface Info {
    label: string;
    content: string;
    show?: boolean;
    name: 'OrderNumber' | 'CreateTime' | 'Status' | 'Type' | 'Payer'|'NextTime';
}
export default defineComponent({
    props: {
        orderDetail: {
            type: Object as PropType<orderType.OrderInfo>,
            required: true
        },
        userType: {
            type: String as PropType<UserType | 'superManager'>,
            required: true
        }
    },
    setup(props) {
        const PaypalOrderAction = ['0', '3', '5'];
        const info: Array<Info> = reactive([{
            label: WordList.OrderOrderNumber,
            content: '',
            name: 'OrderNumber'
        }, {
            label: WordList.TabelMessageBoxCreateTime,
            content: '',
            name: 'CreateTime'
        }, {
            label: WordList.MulListUpdateListTanleStatus,
            content: '',
            name: 'Status'
        }, {
            label: WordList.TabelKeyInHtmlType,
            content: '',
            name: 'Type'
        }, {
            label: WordList.ProperAllTextNextExpireTime,
            name: 'NextTime',
            content: '',
            show: false
        }, {
            label: WordList.ProperAllTextPayer,
            content: '',
            name: 'Payer'
        }]);
        const platformInfoText = ref('');
        const platformInfoOrderText = ref('');
        watch(computed(() => props.orderDetail), () => {
            console.log(props.orderDetail);
            platformInfoText.value = [
                WordList.ProperAllTextPaypalInfo,
                WordList.ProperAllTextStripeInfo
            ][Number(props.orderDetail.PayPlatform)];

            platformInfoOrderText.value = [
                WordList.ProperAllTextPaypalOrder,
                WordList.ProperAllTextStripeOrder
            ][Number(props.orderDetail.PayPlatform)];

            for (let index = 0; index < info.length; index += 1) {
                info[index].content = props.orderDetail[info[index].name];
                if (props.orderDetail.TypeEnum === '8') {
                    info[index].show = true;
                }
            }
        });

        const invoice = () => {
            // 物业的地址installgetreceipt；installer社区和个人地址为installgetreceipt
            if (props.userType === 'installer' || props.userType === 'community') {
                subscriptionApi.downInvoice(props.orderDetail.ID, 'v3/web/common/order/getReceipt');
            } else if (props.userType === 'distributorIns' || props.userType === 'distributorCom' || props.userType === 'distributorOffice') {
                subscriptionApi.downInvoice(props.orderDetail.ID, 'v3/web/common/order/getReceipt');
            } else if (props.userType === 'propertyCommunity') {
                subscriptionApi.downInvoice(props.orderDetail.ID, 'v3/web/common/order/getReceipt');
            } else if (props.userType === 'superManager') {
                subscriptionApi.downInvoice(props.orderDetail.ID, 'v3/web/common/order/getReceipt', props.orderDetail.Payer);
            }
        };
        const showPaypalOrderInfo = (StatusEnum: string) => {
            if (!PaypalOrderAction.includes(StatusEnum)) {
                return true;
            }
            return false;
        };

        return {
            info,
            platformInfoText,
            platformInfoOrderText,
            invoice,
            PaypalOrderAction,
            showPaypalOrderInfo
        };
    }
});
