import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"font-size":"18.1px","word-break":"break-word"} }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    footerType: "customize",
    title: _ctx.$wordList.LoginTips,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    footer: _withCtx(() => [
      _createVNode("span", _hoisted_2, [
        _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextPayItLater), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.PayNow
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextPayItNow), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode("p", _hoisted_1, _toDisplayString(_ctx.showText), 1)
    ]),
    _: 1
  }, 8, ["title"]))
}