import HttpRequest from '@/util/axios.config';

interface DataRequestResult {
    msg: string;
    code: number;
}
const cancelOrder = (data: object, callback: Function) => {
    HttpRequest.post('v3/web/community/order/cancelorder', data, (res: DataRequestResult) => {
        callback(res.msg);
    });
};

export default null;
export {
    cancelOrder
};