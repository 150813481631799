import info from './info.vue';
import userInfo from './user-info.vue';
import manageInfo from './manage-info.vue';
import payProcessing from './pay-processing.vue';
import tipsDialog from './tips-dialog.vue';
import * as cancel from './cancel';

export default info;
export {
    userInfo,
    manageInfo,
    payProcessing,
    tipsDialog,
    cancel
};